<template>
  <div>
    <a-upload
      list-type="picture-card"
      class="avatar-uploader"
      :customRequest="uploadfiles"
      :before-upload="beforeUpload"
      @change="handleChange"
      :show-upload-list="false"
    >
      <img v-if="imageUrl" :src="imageUrl" alt="avatar" style="width: 100%" />
      <div v-else>
        <LoadingOutlined v-if="loading" />
        <PlusOutlined v-else />
        <div class="ant-upload-text">{{ title }}</div>
      </div>
    </a-upload>
  </div>
</template>

<script>
import axios from "axios";
import { defineComponent, reactive, computed, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  props: ["title", "bucketName", "resolution", "imageUrl"],
  emits: ["callback"],
  components: {
    LoadingOutlined,
    PlusOutlined,
  },
  setup(props, { emit }) {
    const store = useStore();
    const state = reactive({
      currentUser: computed(() => store.getters["auth/currentUser"]),
      imageUrl: props.imageUrl || "",
      imageUpload: "",
      loading: false,
      title: props.title || "Upload",
      bucketName: props.bucketName,
      resolution: props.resolution || false,
    });

    watch(props, (val) => {
      state.title = props.title;
      state.bucketName = props.bucketName;
      state.imageUrl = props.imageUrl;
    });

    const beforeUpload = (file) => {
      return new Promise((reslove, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", (event) => {
          const _loadedImageUrl = event.target.result;
          const image = document.createElement("img");
          image.src = _loadedImageUrl;
          image.onload = () => {
            if (state.resolution) {
              const imageResolition = image.width / image.height;
              console.log("image", image.width, image.height);
              console.log("imageResolition", imageResolition);
              console.log("state.resolution", state.resolution);
              if (
                imageResolition < state.resolution - 0.1 ||
                imageResolition > state.resolution + 0.1
              ) {
                message.error("Invalid Iamge Resolition");
                reject();
              }
            }

            const isContain = true;
            const isJpgOrPng =
              file.type === "image/gif" ||
              file.type === "image/jpeg" ||
              file.type === "image/png";
            if (!isJpgOrPng) {
              message.error("You can only upload JPG file!");
              reject();
            }
            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isLt5M) {
              message.error("Image must smaller than 5MB!");
              reject();
            }
            return reslove(isJpgOrPng && isLt5M && isContain);
          };
        });
      });
    };
    const uploadfiles = async ({ file }) => {
      // ลบภาพก่อนหน้านี้ (กรณีอัพภาพแรกมาแล้ว แต่อยากอัพครั้งที่สอง)
      // if (!_.isEmpty(state.imageUrl)) {
      //   await store.dispatch("bucket/deleteImage", {
      //     bucketName: state.bucketName,
      //     fileName: state.imageUpload.fileName,
      //   });
      // }

      const uploadlink = await store.dispatch("bucket/uploadLink", {
        contentType: file.type,
        bucketName: state.bucketName,
      });

      if (!uploadlink) {
        return message.error("ลิงก์อัพโหลดผิดพลาด");
      }

      axios
        .put(uploadlink.data.uploadUrl, file, {
          headers: {
            "Content-Type": file.type,
          },
        })
        .then(async (result) => {
          if (result.status == 200) {
            state.imageUpload = { ...state.imageUpload, ...uploadlink.data };
            // get link for public url
            const publicUrl = await store.dispatch(`bucket/getPrivateUrl`, {
              bucketName: state.imageUpload.bucketName,
              fileName: state.imageUpload.fileName,
            });

            state.imageUrl = publicUrl.data.url; // show img when success
            emit("callback", state.imageUpload);
          } else {
            throw "ลิงก์อัพโหลดผิดพลาด";
          }
        })
        .catch((err) => {
          console.log(err);
          return message.error("ลิงก์อัพโหลดผิดพลาด");
        });
    };

    const handleChange = (info) => {
      if (info.file.status === "uploading") {
        state.fileUpload = info.file.originFileObj;
        return;
      }
    };

    return {
      ...toRefs(state),
      handleChange,
      beforeUpload,
      uploadfiles,
    };
  },
});
</script>
