<template>
  <office-layout>
    <template v-slot:breadcrumb>
      <a-breadcrumb>
        <a-breadcrumb-item>
          <HomeOutlined />
          <router-link to="/">
            <span>Home</span>
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="{ name: 'redeem' }">
            <span>Redeem</span>
          </router-link>
        </a-breadcrumb-item>
      </a-breadcrumb>
    </template>
    <div>
      <div
        v-if="profile.profile.hotelId.quota < 1"
        style="padding-bottom: 20px"
      >
        <a-col :md="{ span: 24 }" :xs="{ span: 24 }" justify="center">
          <a-alert message="Quota redeem is full." type="error" />
        </a-col>
      </div>

      <div v-if="profile.profile.hotelId.quota > 0">
        <a-col :md="{ span: 24 }" :xs="{ span: 24 }" justify="center">
          <a-row style="padding-bottom: 20px" type="flex" justify="end">
            <router-link
              :to="{
                name: 'redeem.addRedeem',
              }"
            >
              <a-button type="primary" size="large">
                <PlusCircleOutlined />Add Redeem
              </a-button>
            </router-link>
          </a-row>
        </a-col>
      </div>

      <a-col :md="{ span: 24 }" :xs="{ span: 24 }">
        <a-table
          :columns="columns"
          :row-key="(record) => record.id"
          :data-source="dataSource"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
        >
          <template
            #filterDropdown="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
          >
            <div style="padding: 8px">
              <a-input
                ref="searchInput"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
              >
                <template #icon><SearchOutlined /></template>
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px"
                @click="handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
          </template>
          <template #filterIcon="filtered">
            <search-outlined
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
          </template>
          <template #firstname="{ record }">
            {{ record.name }}
          </template>
          <template #arrivalPort="{ record }">
            {{ record.arrivalPort }}
          </template>
          <template #total="{ record }">
            {{ record.total }}
          </template>
          <template #checkdate="{ record }">
            {{ moment(record.bookingCheckIn).format("YYYY-MM-DD") }} to
            {{ moment(record.bookingCheckOut).format("YYYY-MM-DD") }}
            <br />
            <br />
            Rooms: {{ record.rooms }}<br />
            Nights: {{ record.nights }}<br />
            Room nights: {{ record.roomNights }}
          </template>
          <template #documents="{ record }">
            <a-button
              type="link"
              @click="
                record.bookingConfirmation
                  ? openDocument(
                      record.bookingConfirmation.bucketName,
                      record.bookingConfirmation.fileName
                    )
                  : showModalUploadImg(
                      'Hotel Booking Confirmation',
                      'bookingConfirmation',
                      record.id
                    )
              "
              primary
            >
              <div v-if="record.bookingConfirmation">Booking Confirmation</div>
              <div v-else class="uploadImgLabel">
                Upload Booking Confirmation
              </div>
            </a-button>
            <br />
            <a-button
              type="link"
              @click="
                record.bordingPass
                  ? openDocument(
                      record.bordingPass.bucketName,
                      record.bordingPass.fileName
                    )
                  : showModalUploadImg(
                      'Customer Boarding Pass or Air Ticket',
                      'bordingPass',
                      record.id
                    )
              "
              primary
            >
              <div v-if="record.bordingPass">Boarding Pass or Air Ticket</div>
              <div v-else class="uploadImgLabel">Upload Boarding Pass or Air Ticket</div>
            </a-button>
            <br />
            <a-button
              type="link"
              @click="
                record.folio
                  ? openDocument(record.folio.bucketName, record.folio.fileName)
                  : showModalUploadImg('Customer Folio', 'folio', record.id)
              "
              primary
              ><div v-if="record.folio">Folio</div>
              <div v-else class="uploadImgLabel">Upload Folio</div></a-button
            >
          </template>
          <template #status="{ record }">
            <a-popconfirm
              v-if="record.status === 'AWAIT_APPROVE'"
              title="Are you sure reject this redeem?"
              ok-text="Reject"
              cancel-text="Cancel"
              @confirm="confirmReject(record.id)"
            >
              <a-button
                :class="{
                  waiting: record.status === 'AWAIT_APPROVE',
                  approved: record.status === 'APPROVE',
                  reject: record.status === 'REJECT',
                }"
              >
                <div v-if="record.status === 'AWAIT_APPROVE'">
                  AWAIT APPROVE
                </div>
                <div v-else>{{ record.status }}</div>
              </a-button>
            </a-popconfirm>
            <p
              v-else
              :class="{
                waiting: record.status === 'AWAIT_APPROVE',
                approved: record.status === 'APPROVE',
                reject: record.status === 'REJECT',
              }"
            >
              {{ record.status }}
            </p>
          </template>
        </a-table>
      </a-col>

      <a-modal
        v-model:visible="visibleUploadImg"
        :title="`${conditionUpload.titleName}`"
        @ok="handleOkModalUploadImg"
      >
        <ImgUpload
          title="Click to upload"
          bucketName="hotelcertificate"
          @callback="setImage"
        />
      </a-modal>
    </div>
  </office-layout>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import { usePagination } from "vue-request";
import {
  HomeOutlined,
  SearchOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons-vue";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  reactive,
  toRefs,
} from "vue";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
import ImgUpload from "../../components/ImgUpload";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    HomeOutlined,
    SearchOutlined,
    PlusCircleOutlined,
    ImgUpload,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      loading: false,
      current: 1,
      total: 0,
      pageSize: 10,
      searchText: "",
      searchedColumn: "",
    });
    const searchInput = ref();

    /* -------------
    - Modal Upload File -
    ------------- */
    const visibleUploadImg = ref(false); // for modal
    const conditionUpload = ref({
      titleName: null,
      nameType: null,
      redeemId: null,
    });
    const imgFile = reactive({}); // for save data img file
    const showModalUploadImg = (title, type, redeemId) => {
      visibleUploadImg.value = true;
      conditionUpload.value.titleName = title;
      conditionUpload.value.nameType = type;
      conditionUpload.value.redeemId = redeemId;
    };

    const handleOkModalUploadImg = async (e) => {
      visibleUploadImg.value = false;

      try {
        if (imgFile.value) {
          let dataDoc = {};
          if (conditionUpload.value.nameType == "bookingConfirmation") {
            dataDoc = {
              packageId: conditionUpload.value.redeemId,
              bookingConfirmation: {
                bucketName: imgFile.value.bucketName,
                fileName: imgFile.value.fileName,
              },
            };
          } else if (conditionUpload.value.nameType == "bordingPass") {
            dataDoc = {
              packageId: conditionUpload.value.redeemId,
              bordingPass: {
                bucketName: imgFile.value.bucketName,
                fileName: imgFile.value.fileName,
              },
            };
          } else if (conditionUpload.value.nameType == "folio") {
            dataDoc = {
              packageId: conditionUpload.value.redeemId,
              folio: {
                bucketName: imgFile.value.bucketName,
                fileName: imgFile.value.fileName,
              },
            };
          } else {
            message.error("Document type is error.");
            return;
          }
          await store.dispatch(`redeem/updateRedeem`, dataDoc);
          message.success("Add document image is success.");
          dataDoc = {}; // clear data
          imgFile.value = {}; // clear data
          location.reload();
        } else {
          message.error("Add document image is incomplete.");
        }
      } catch (e) {
        console.log(e);
        message.error(
          (e.response && e.response.data && e.response.data.message) ||
            "This is an error message, cannot save image, please try again."
        );
      }
    };
    const setImage = (res) => {
      imgFile.value = {
        bucketName: res.bucketName,
        fileName: res.fileName,
      };
    };

    const fetchRedeemList = async (page = 1, filters = {}, sorter = {}) => {
      try {
        state.loading = true;
        let payload = {
          page,
          pageSize: state.pageSize,
          ...filters,
        };

        if (sorter.field) {
          payload = {
            ...payload,
            sortField: sorter.field,
            sortOrder: sorter.order == "ascend" ? 1 : -1,
          };
        } else {
          payload = {
            ...payload,
            sortField: "createdAt",
            sortOrder: -1, // desc
          };
        }

        const result = await store.dispatch(`redeem/fetchRedeemList`, payload);
        state.current = page;
        state.total = result.total;
        state.loading = false;
      } catch (error) {
        state.loading = false;
      }
    };

    const openDocument = async (bucketName, fileName) => {
      try {
        const { data } = await store.dispatch(`bucket/getPrivateUrl`, {
          bucketName,
          fileName,
        });
        window.open(data.url, "_blank");
      } catch (error) {
        message.error("Cannot get link");
      }
    };

    onMounted(async () => {
      await fetchRedeemList();
    });

    const handleTableChange = async (pag = {}, filters, sorter) => {
      await fetchRedeemList(pag.current, filters, sorter);
    };

    const handleSearch = async (selectedKeys, confirm, dataIndex) => {
      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;
      await fetchRedeemList(1, { [`${dataIndex}`]: selectedKeys });
      confirm();
    };

    const handleReset = async (clearFilters) => {
      state.searchText = "";
      await fetchRedeemList();
      clearFilters();
    };

    const confirmReject = async (id) => {
      try {
        await store.dispatch("redeem/reject", id);
        message.success("Rejected!!");
        await fetchRedeemList();
      } catch (error) {
        message.error("Reject Failed!!");
      }
    };

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Arrival Port",
        dataIndex: "arrivalPort",
        sorter: true,
        slots: {
          filterDropdown: "filterDropdown",
          filterIcon: "filterIcon",
          customRender: "arrivalPort",
        },
      },
      {
        title: "Total(฿)",
        dataIndex: "total",
        sorter: true,
        slots: {
          filterDropdown: "filterDropdown",
          filterIcon: "filterIcon",
          customRender: "total",
        },
      },
      {
        title: "Date",
        slots: {
          customRender: "checkdate",
        },
      },
      {
        title: "Document",
        slots: {
          customRender: "documents",
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        sorter: true,
        slots: {
          filterDropdown: "filterDropdown",
          filterIcon: "filterIcon",
          customRender: "status",
        },
      },
    ];

    return {
      moment,
      ...toRefs(state),
      dataSource: computed(() => store.getters["redeem/getDataList"]),
      pagination: computed(() => ({
        total: state.total,
        current: state.current,
        pageSize: state.pageSize,
      })),
      profile: computed(() => store.getters["auth/currentUser"]),
      handleTableChange,
      handleSearch,
      handleReset,
      searchInput,
      columns,
      openDocument,
      confirmReject,
      visibleUploadImg,
      conditionUpload,
      showModalUploadImg,
      handleOkModalUploadImg,
      setImage,
    };
  },
});
</script>

<style scoped>
.waiting {
  color: rgb(158, 146, 14);
}
.approved {
  color: green;
}
.reject {
  color: red;
}
.uploadImgLabel {
  color: rgb(189, 184, 184);
}
</style>
